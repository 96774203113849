.fullDiv,html,body{
  height: 100%;

}
::-webkit-scrollbar {
  display: none;
}

#root{
  background: gray;
}
.options{
  display: flex;
  justify-content: center;
  align-items: center;
}
.options  > button{
  font-size: 20px;
  height: fit-content;
  margin: 20px;
  color: white;
  background: dodgerblue;
  padding: 15px;
  border-radius: 10px;
  border:none;
}

.image{
  object-fit: cover;
}
.imageOptions{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px;
}
.option{
  border-radius: 5px;
  border: black solid 5px;

}